import Vue from 'vue'
import Adsense from 'vue-google-adsense/dist/Adsense.min.js'

import App from './App.vue'
import router from './router'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css';
import 'vue-transitions-css';
import VueGtag from "vue-gtag";


import globals from "./globals";

Vue.config.productionTip = false

//global variables
Vue.prototype.$bhrt = globals;

Vue.use(require('vue-script2'));
Vue.use(Adsense);
Vue.use(VueGtag, {
  config: { id: "UA-149496129-1" }
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
