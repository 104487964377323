<template>
  <div class="container">
    <div id="header-cont">
      <router-link to="/" title="Naslovna" id="header-logo">
        <img src="../assets/images/header-logo.svg" />
      </router-link>

      <div id="menu-cont">
        <div
          v-for="(navItem, navItemKey) in navItems"
          :key="navItemKey"
          :class="{ hastab: 'subItems' in navItem }"
          class="menucont"
        >
          <img
            v-if="navItem.hasOwnProperty('icon')"
            :src="require(`../assets/images/${navItem.icon}`)"
            class="menu_icon"
          />

          <nav-item
            :nav-item="navItem"
            @mouseover.native="toogleSubItems"
            @mouseleave.native="toogleSubItems"
            class="hover-hook"
          />

          <div v-if="navItem.hasOwnProperty('subItems')" class="open_menu_tabs">
            <img
              src="../assets/images/open_menu_tabs.svg"
              @click="toogleSubItems"
            />
          </div>

          <div
            v-if="navItem.hasOwnProperty('subItems')"
            class="menu_subcont d-none"
            @mouseleave="hideContainer($event.target)"
          >
            <nav-item
              v-for="(subItem, subItemKey) in navItem.subItems"
              :key="subItemKey"
              :nav-item="subItem"
              @click.native="hideContainer($event.target.parentElement)"
            />
          </div>
        </div>

        <socials-header />

        <special-links />
      </div>

      <header-top-menu />
    </div>
    <hamburger-menu />


    <span class="clearfix"></span>
  </div>
</template>

<script>
import NavItem from "../components/NavItem";
import SpecialLinks from "./SpecialLinks";
import SocialsHeader from "./SocialsHeader";
import HeaderTopMenu from "./HeaderTopMenu";
import HamburgerMenu from "./HamburgerMenu";
import MainMixin from "../mixins/nav/MainMixin";
import LangMixin from "../mixins/LangMixin";
import ArticleCategoryListMixin from "../mixins/ArticleCategoryListMixin";

export default {
  components: {
    HamburgerMenu,
    HeaderTopMenu,
    SocialsHeader,
    SpecialLinks,
    NavItem,
  },
  mixins: [MainMixin, LangMixin, ArticleCategoryListMixin],

  watch: {
    $route() {
      this.$nextTick(() => this.scrollViewToPageHeader());
    },
  },

  methods: {
    toogleSubItems(event) {
      let target;

      if (event.type === "click") {
        target = event.target.parentElement.parentElement;
      } else {
        target = event.target.parentElement;
      }

      const subMenu = target.querySelector(".menu_subcont");

      if (subMenu) {
        subMenu.classList.toggle("d-none");
      }
    },

    hideContainer(target) {
      target.classList.toggle("d-none");
    },

    scrollViewToPageHeader() {
      document
        .getElementById("header-cont")
        .scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.hover-hook {
  display: inline-block;
  height: 25px;
}
</style>